import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import Slider from 'react-slick'
import ArrowLeft from 'src/assets/images/arrow-left.svg'
import ArrowRight from 'src/assets/images/arrow-right.svg'

import Section from 'src/components/Section'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import TextLockup from 'src/components/TextLockup'
import Grid from 'src/components/Grid'
import CocktailCard from 'src/components/CocktailCard'
import { colors, mq, animations } from 'src/styles'

const Wrapper = styled(Section)`

`

const ColumnWrapper = styled.div`
	h1, h2, h3 {
		max-width: 20em;
	}
	h4, h4 {
		max-width: 26em;
	}
	h6 {
		max-width: 16em;
	}
	p {
		max-width: 40em;
	}
`

const InfoWrap = styled(ColumnWrapper)`
	position: relative;
	padding-bottom: 50px;
	overflow: hidden;
	p {
		max-width: 32em;
	}
	${ mq.mediumAndBelow } {
		padding-right: 7.5vw;
		padding-left: 7.5vw;
	}
`

const CocktailsWrap = styled.div`
	overflow: hidden;
`

const ArrowControl = styled.button`
	appearance: none;
	width: 50px;
	height: 50px;
	padding: 0;
	cursor: pointer;
	${ mq.largeAndBelow } {
		margin-top: 30px;
	}
	${ ({ position }) => position === 'left' ? `
		margin-right: 10px;
	` : `
		margin-left: 10px;
	` }
	background: transparent;
	color: ${ colors.mainColor };
	border: none;
	outline: none;
	transition: color ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out;
	${ ({ hide }) => hide && `
		// opacity: 0;
		// pointer-events: none;
	` }
	&:hover {
		color: ${ colors.pink2 };
	}
	svg {
		width: 50px;
		height: 50px;
		display: block;

		* {
			// stroke: currentcolor;
			// stroke-width: 1px;
			// stroke-linecap: butt;
			// stroke-linebutt: butt;
			// stroke-linejoin: miter;
			transition: fill ${ animations.mediumSpeed } ease-in-out;
			fill: ${ colors.yellow };
		}
		&:hover {
			* {
				fill: ${ colors.pink5 };
			}
		}
	}
`

const StyledSlider = styled(Slider)`
	.slick-track {
		${ mq.largerAndUp } {
			display: flex;
			justify-content: flex-end;
		}
	}
	&:hover {
		${ ArrowControl } {
			transform: none;
		}
	}
`

const Slide = styled.div`
	padding: 0 20px 0 0;
	> div {
		width: 60vw;
	}
	${ mq.mediumAndUp } {
		> div {
			width: 40vw;
		}
	}
	${ mq.largerAndUp } {
		> div {
			width: auto;
		}
	}
	${ mq.largeAndBelow } {
		padding: 0 10px;
	}
`

const FeaturedCocktails = ({
	className,
	theme,
	nextTheme,
	prevTheme,
	info,
	cocktails,
	index
}) => {
	const customSlider = useRef()

	const NextArrow = ({ onClick }) => <ArrowControl position="right" onClick={onClick} aria-label='Go to next image'><ArrowRight/></ArrowControl>
	const PrevArrow = ({ onClick }) => <ArrowControl position="left" onClick={onClick} aria-label='Go to previous image'><ArrowLeft/></ArrowControl>

	function goToNextSlide () {
		customSlider.current.slickNext()
	}

	function goToPrevSlide () {
		customSlider.current.slickPrev()
	}

	const settings = {
		dots: false,
		arrows: false,
		infinite: true,
		autoplay: false,
		centerMode: false,
		swipe: true,
		accesibility: false,
		speed: 500, // swipe animation speed
		variableWidth: false,
		slidesToShow: 3.5,
		slidesToScroll: 1,
		// nextArrow: <NextArrow/>,
		// prevArrow: <PrevArrow onClick={goToPrevSlide}/>,
		responsive: [
			{
				breakpoint: 1300,
				settings: {
					slidesToShow: 2.5,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					centerMode: true,
					variableWidth: true,
					slidesToShow: 1,
					// nextArrow: slides.length > 1 ? <NextArrow /> : false,
					// prevArrow: slides.length > 1 ? <PrevArrow /> : false,
				}
			}
		]
	}

	return (
		<Wrapper className={className} setTheme={theme} nextTheme={nextTheme} prevTheme={prevTheme} index={index}>
			<Grid
				small='[12]'
				large='1 [8] 1 [16]'
				vAlign='top'
			>
				{ info && (
					<InfoWrap>
						<TextLockup
							eyebrow={info.eyebrow}
							text={info.text}
							textSize={info.paragraphSize}
							actions={info.actions}
							theme={info.theme}
							alignment={info.alignment}
						/>
						<ResponsiveComponent small={<span/>} large={
							<div css={css`padding-top: 50px; display: flex; align-items: center; justify-content: center;`}>
								<PrevArrow onClick={goToPrevSlide}/>
								<NextArrow onClick={goToNextSlide}/>
							</div>
						}/>
					</InfoWrap>
				)}

				<CocktailsWrap>
					<div style={{ overflow: 'hidden' }}>
						<StyledSlider
							{...settings}
							ref={slider => (customSlider.current = slider)}
						>
							{cocktails.map((slide, index) => {
								if (!slide.image) {
									return false
								}
								return (
									<Slide key={slide.id + '_slide'} slideCount={cocktails.length}>
										<CocktailCard
											title={slide.title}
											tagline={slide.tagline}
											image={slide.thumbnail}
											slug={slide.slug}
											index={index}
											sizes={'(min-width: ' + mq.largerBreakpoint + 'px) ' + '467px, ' + '(min-width: ' + mq.mediumBreakpoint + 'px) ' + '28vw, ' + '(min-width: ' + mq.smallBreakpoint + 'px) ' + '42vw, 86vw'}
										/>
									</Slide>
								)
							})}
						</StyledSlider>
					</div>
					<ResponsiveComponent large={<span/>} small={
						<div css={css`display: flex; align-items: center; justify-content: center;`}>
							<PrevArrow onClick={goToPrevSlide}/>
							<NextArrow onClick={goToNextSlide}/>
						</div>
					}/>
				</CocktailsWrap>
			</Grid>
		</Wrapper>
	)
}

export default FeaturedCocktails
