import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import Section from 'src/components/Section'
import Link from 'src/components/Link'
import TextLink from 'src/components/TextLink'
import ScrollEntrance from 'src/components/ScrollEntrance'
import ThemeSelector from 'src/components/ThemeSelector'
import { underlineColors } from 'src/components/TextLockup'
import Grid from 'src/components/Grid'
import { themes } from 'src/styles/themes'
import { decodeProductId } from 'src/utils/format'
import { GatsbyImage } from 'gatsby-plugin-image'

import { animations, colors, mq, typography, globals, util } from 'src/styles'

const Wrapper = styled(Section)`
  ${ ({ isFirstSection }) => isFirstSection ? `
    padding-top: 0 !important;
  ` : '' }
  ${ ({ productPage }) => productPage ? `
    padding-bottom: 0 !important;
  ` : '' }
`

const ProductCard = styled(Link)`
  display: block;
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
  ${ ({ clipId = 'raggedThumb1' }) => `
    clip-path: url(#${ clipId });
  ` }
  ${ ({ card, theme }) => `
    background-color: ${ themes[theme].background };
    color: ${ themes[theme].color };
    --bg-color: ${ themes[theme].background };
		--text-color: ${ themes[theme].color };
		--main-color: ${ themes[theme].hoverColor };
  ` }
`

const CardImg = styled(GatsbyImage)`
  aspect-ratio: 1;
	img {
    transition: all ${ animations.mediumSpeed } ease-in-out !important;
    object-fit: cover !important;
  }
`

const CardInfo = styled.div`
  padding: 7% 8% 12%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  text-align: center;
  text-wrap: balance;
  row-gap: 20px;
  column-gap: 20px;
  h3 {
    margin: 0 0 5px;
  }
  ${ mq.largerAndUp } {
    padding: 3% 6% 5%;
    flex-direction: row;
    text-align: left;
    h3 {
      margin: -.3em;
    }
  }
  > div:last-of-type {
    flex-shrink: 0;
  }
  .tagline {
    ${ typography.bodySmall }
    line-height: 1.4em;
    max-width: 20em;
    ${ mq.largerAndUp } {
      max-width: 100%;
    }
    u {
      display: inline-block;
      position: relative;
      text-decoration: none;
      svg {
        position: absolute;
        width: 100%;
        display: block;
        top: calc(100% + 0em);
        opacity: .5;
        overflow: visible;
        height: 0.2em;
        * {
          fill: none;
          stroke-width: 2px;
          stroke: var(--main-color);
        }
      }
    }
  }
`

const FeaturedProduct = ({
  className,
  theme,
  cardTheme,
  prevTheme,
  nextTheme,
  isFirstSection,
  beverageProduct,
  productPage,
  imageOnLeft = true,
  product,
  index,
  purchaseLink,
}) => {
  const { allShopifyProductVariant, allContentfulBeverageProduct } = useStaticQuery(
    graphql`
      query {
        allContentfulBeverageProduct {
          edges {
            node {
              ...BeverageProduct
            }
          }
        }
        allShopifyProductVariant {
          edges {
            node {
              ...Variant
            }
          }
        }
      }
    `
  )

  const getMetafield = (key, product) => {
    const metafields = product?.metafields?.filter(field => field.key === key)
    if (metafields && metafields.length > 0) {
      return metafields[0].value
    }
    return false
  }

  console.log('CMS products: ', product)

  return (
    <Wrapper
      className={className}
      setTheme='default'
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      isFirstSection={isFirstSection}
      index={index}
      productPage={productPage}
    >
      <Grid
        as={Grid}
        small="1 [10] 1"
        large='1 [6] [6] 1'
        vAlign="stretch"
        rowGap="7vw"
        colGap={['2vw', '2vw', '2vw']}
      >
        {product?.map((prod, index) => {
          if (!prod?.shopifyProductId) {
            return false
          }

          console.log(prod)
          const beverageProductId = decodeProductId(prod.shopifyProductId)
          const variant = allShopifyProductVariant.edges.filter(variant => variant.node.product.shopifyId === beverageProductId)[0]?.node
          const product = variant?.product

          let tagline = getMetafield('tagline', product)

          if (tagline) {
            tagline = tagline.replace(/<u>/g, "<u><svg width='115px' height='5px' viewBox='0 0 115 5' preserveAspectRatio='none'><path d='M0.0810546875,4.28027344 C25.2267225,-2.34828249 39.183906,1.56675172 67.3291088,2.38867188 C82.9115465,2.84372347 98.500327,2.02213543 114.085938,1.83886719' id='Path-2-Copy' stroke='var(--main-color)'></path></svg>")
          }

          const clips = [
            'raggedThumb1',
            'raggedThumb2'
          ]

          return (
            <ScrollEntrance key={prod.shopifyProductId + '-' + index}>
              <ProductCard
                clipId={clips[index]}
                to={'/shop/' + product.handle}
                theme={prod.theme}
              >
                <div>
                  <CardImg image={product?.images[0]?.image?.gatsbyImageData} />
                  <CardInfo>
                    <div>
                      <h3 className='h5'><span className="handwritten">{product?.title}</span></h3>
                      <div className="body tagline" dangerouslySetInnerHTML={{ __html: tagline }} />
                    </div>
                    <div>
                      <TextLink active>Shop Now</TextLink>
                    </div>
                  </CardInfo>
                </div>
              </ProductCard>
            </ScrollEntrance>
          )
        })}
      </Grid>
    </Wrapper>
  )
}

export default FeaturedProduct
