import React from 'react'
import styled from '@emotion/styled'

import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import CocktailCard from 'src/components/CocktailCard'
import { mq } from 'src/styles'

const Wrapper = styled(Section)``

const CocktailsWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 -6px -60px;
	${ mq.mediumAndUp } {
		margin: 0 -10px -60px;
	}
	${ mq.largeAndUp } {
		margin: 0 -10px;
	}
	${ mq.largerAndUp } {
		margin: 0 -12px;
	}
	> div {
		width: ${ ({ small }) => 100 / small }%;
		flex-grow: 0;
		flex-shrink: 0;
		padding: 0 6px;
		margin-bottom: 60px;
		${ mq.mediumAndUp } {
			padding: 0 10px;
		}
		${ mq.largeAndUp } {
			padding: 0 10px;
			width: ${ ({ medium }) => 100 / medium }%;
		}
		${ mq.largerAndUp } {
			padding: 0 12px;
			width: ${ ({ large }) => 100 / large }%;
		}
	}
`

const CocktailList = ({
	className,
	nextTheme,
	prevTheme,
	theme,
	cocktails,
	isFirstSection,
	id,
	index,
}) => {
	return (
		<Wrapper
			className={className}
			prevTheme={prevTheme}
			setTheme={theme}
			nextTheme={nextTheme}
			isFirstSection={isFirstSection}
			index={index}
		>
			<Grid small="container" larger='2 [10] 2' extraLarge='5 [18] 5'>
				<CocktailsWrap small="2" medium="2" large="3" vAlign="top">
					{cocktails.map((cocktail, index) => {
						return (
							<CocktailCard
								key={index}
								title={cocktail.title}
								tagline={cocktail.tagline}
								image={cocktail.thumbnail}
								slug={cocktail.slug}
								index={index}
							/>
						)
					})}
				</CocktailsWrap>
			</Grid>
		</Wrapper>
	)
}

export default CocktailList
