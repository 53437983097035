import React from 'react'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Link from 'src/components/Link'

import { mq, animations } from 'src/styles'

const Wrapper = styled(Section)``

const MerchWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 -10px -40px;
	${ mq.largeAndUp } {
		margin: 0 -15px;
	}
	${ mq.largerAndUp } {
		margin: 0 -20px;
	}
	> div {
		width: ${ ({ small }) => 100 / small }%;
		flex-grow: 0;
		flex-shrink: 0;
		padding: 0 10px;
		margin-bottom: 40px;
		${ mq.largeAndUp } {
			padding: 0 15px;
			width: ${ ({ medium }) => 100 / medium }%;
		}
		${ mq.largerAndUp } {
			padding: 0 20px;
			width: ${ ({ large }) => 100 / large }%;
		}
	}
`

const HoverImageWrap = styled.div`
	clip-path: url(#raggedThumb2);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`

const HoverImage = styled(GatsbyImage)`
	// display: none;

	opacity: 0;
	transition: opacity ${ animations.mediumSpeed } ease-in-out;
`

const MerchItem = styled(Link)`
	display: block;
	&:hover {
		${ HoverImage } {
			opacity: 1;
		}
	}
`

const MerchItemWrap = styled.div`

`

const ImageWrap = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	margin-bottom: 16px;
`

const ThumbnailImage = styled(GatsbyImage)`
	> div:first-of-type {
		padding-top: 100% !important;
	}
`

const MerchItemTitle = styled.h6`
	text-align: center;
`

const MerchList = ({
	className,
	nextTheme,
	prevTheme,
	theme,
	index,
	isFirstSection,
	id,
}) => {
	const { allShopifyProduct } = useStaticQuery(graphql`
		query {
			allShopifyProduct(filter: {productType: {ne: "Beverages"}}) {
				edges {
					node {
						images: media {
							... on ShopifyMediaImage {
								image {
									gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
								}
							}
						}
						id
						handle
						title
						variants {
							id
							availableForSale
							price
						}
						productType
						shopifyId
						descriptionHtml
						description
						options {
							position
							name
							values
							shopifyId
						}
					}
				}
			}
		}
	`)
	const merchItems = allShopifyProduct.edges
	return (
		<Wrapper
			className={className}
			prevTheme={prevTheme}
			setTheme={theme}
			nextTheme={nextTheme}
			isFirstSection={isFirstSection}
			index={index}
		>
			<Grid small="container">
				<MerchWrap small="1" medium="2" large="3" vAlign="top">
					{merchItems.map((item, index) => {
						item = item.node
						return (
							<MerchItemWrap key={index}>
								<MerchItem to={'/shop/' + item.handle}>
									<ImageWrap>
										<ThumbnailImage
											image={
												item.images[0].image.gatsbyImageData
											}
											loading="eager"
											alt={item.title}
											placeholder="none"
											background="transparent"
											imgStyle={{ objectFit: 'contain' }}
											placehoder="none"
											aspectRatio={1}
										/>
										{item.images[1] && (
											<HoverImageWrap>
												<HoverImage
													image={
														item.images[1].image.gatsbyImageData
													}
													loading="eager"
													alt={item.title}
													placeholder="none"
													background="transparent"
													aspectRatio={1}
												/>
											</HoverImageWrap>
										)}
									</ImageWrap>
									<MerchItemTitle>{item.title}</MerchItemTitle>
								</MerchItem>
							</MerchItemWrap>
						)
					})}
				</MerchWrap>
			</Grid>
		</Wrapper>
	)
}

export default MerchList
