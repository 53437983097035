import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import { GatsbyImage } from 'gatsby-plugin-image'
import TextLockup from 'src/components/TextLockup'
import { mq, colors, globals, util } from 'src/styles'
import LargeBoxSvg from 'src/assets/images/large-box-01.svg'

const Wrapper = styled(Section)`
	--block-margins: ${ (100 / 28) * 3 }vw;
	overflow: hidden;
	> div {
		position: relative;
		${ globals.verticalSpacing('padding-top', 0.75) }
		${ globals.verticalSpacing('padding-bottom', 0.75) }
		${ mq.mediumAndBelow } {
			${ globals.verticalSpacing('padding-top', 1.1) }
			${ globals.verticalSpacing('padding-bottom', 1.1) }
		}
	}
`

const ColumnWrapper = styled.div`
	text-align: center;
	position: relative;
	z-index: 2;
	${ mq.largerAndUp } {
		${ ({ mediaPlacement }) => mediaPlacement === 'left' ? `
			text-align: left;
		` : `
			text-align: right;
			h1, h2, h3, h4, h5, h6, p {
				margin-left: auto;
			}
		` };
	}
	h1, h2, h3, h4, h5, h6, p {
		${ mq.largeAndBelow } {
			margin-left: auto;
			margin-right: auto;
		}
	}
	h1,
	h2,
	h3 {
		max-width: 20em;
	}
	h4,
	h4 {
		max-width: 26em;
	}
	h6 {
		max-width: 16em;
	}
	p {
		max-width: 40em;
	}
`

const LineImage = styled(GatsbyImage)`
	margin-bottom: 4%;
	${ mq.largeAndBelow } {
		display: none;
	}
	${ mq.largerAndUp } {
		${ ({ placement }) => placement === 'left' ? `
			margin-left: var(--block-margins);
			margin-right: calc(var(--block-margins) * -2);
		` : `
			margin-right: var(--block-margins);
			margin-left: calc(var(--block-margins) * -2);
		` }
	}
`

const MainImage = styled(GatsbyImage)`
	${ mq.largerAndUp } {
		${ ({ placement }) => placement === 'left' ? `
			margin-right: calc(var(--block-margins) * -1);
		` : `
			margin-left: calc(var(--block-margins) * -1);
		` }
	}
`

const BoxBg = styled(LargeBoxSvg)`
	position: absolute;
	left: ${ 100 / 14 }vw;
	right: ${ 100 / 14 }vw;
	width: calc(100% - ${ 100 / 14 }vw * 2);
	z-index: 1;
	top: 0;
	height: 100%;
	grid-column: none !important;
	${ mq.mediumAndUp } {
		left: var(--block-margins);
		right: var(--block-margins);
		width: calc(100% - var(--block-margins) * 2);
	}
	* {
		${ ({ theme }) => `fill: ${ theme?.background || colors.pink2 };` }
	}
`

const ColumnText = styled(TextLockup)`
	.eyebrow {
		${ util.responsiveStyles('font-size', 18, 16, 16, 15) }
	}
	${ ({ setTheme }) => `
		color: ${ setTheme?.color || colors.textColor };
		svg.short-hr * {
			fill: ${ setTheme?.lineColor || colors.pink4 };
		}
	` }
`

const arrangeMedia = {
	left: {
		normal: '[6] [6]',
		large: '[13] [11]',
		extraLarge: '[14] [10]',
		winXl: {
			normal: '[1] [1]',
			large: '[6] [5]',
			extraLarge: '[13] [9]',
		}
	},
	right: {
		normal: '[1] [1]',
		large: '[13] [11]',
		extraLarge: '[14] [10]',
		winXl: {
			normal: '[1] [1]',
			large: '[6] [5]',
			extraLarge: '[13] [9]',
		}
	}
}

const boxTheme = {
	default: {
		background: colors.white,
		color: colors.textColor,
		lineColor: colors.pink4
	},
	pink: {
		background: colors.pink4,
		color: colors.white,
		lineColor: colors.pink2
	},
	midPink: {
		background: colors.pink2,
		color: colors.textColor,
		lineColor: colors.pink4
	},
	yellow: {
		background: '#EDC28C',
		color: colors.white,
		lineColor: colors.white
	},
	orange: {
		background: colors.orange,
		color: colors.white,
		lineColor: colors.white
	},
}

const SustainabilityBlock = ({
	className,
	setTheme,
	prevTheme,
	nextTheme,
	media,
	lineImage,
	mediaPlacement,
	eyebrow,
	text,
	actions,
	paragraphSize,
	isFirstSection,
	index,
}) => {
	if (!mediaPlacement || mediaPlacement === null) {
		mediaPlacement = 'left'
	}
	const sizes = '(min-width: ' + mq.mediumBreakpoint + 'px) 50vw, 86vw'

	return (
		<Wrapper
			className={className}
			setTheme={prevTheme}
			prevTheme={prevTheme}
			nextTheme={nextTheme}
			isFirstSection={isFirstSection}
			index={index}
		>
			<Grid
				small="[1]"
				large={arrangeMedia[mediaPlacement || 'left'].normal}
				extraLarge={arrangeMedia[mediaPlacement || 'left'].winXl.normal}
				rowGap='30px'
				vAlign={'top'}
				gridDirection={mediaPlacement === 'right' ? 'rtl' : 'ltr'}
			>
				<ColumnWrapper>
					<MainImage image={media.gatsbyImageData} sizes={sizes} placement={mediaPlacement} />
				</ColumnWrapper>

				<ColumnWrapper mediaPlacement={mediaPlacement}>
					<LineImage image={lineImage.gatsbyImageData} sizes={sizes} placement={mediaPlacement} />
					<Grid
						small='4 [20] 4'
						medium='5 [14] 5'
						large={mediaPlacement === 'right' ? '5 [9]' : '[9] 5'}
					>
						<ColumnText
							entranceDelay={1}
							eyebrow={eyebrow}
							text={text}
							textSize={'bodyMedium' || paragraphSize}
							actions={actions}
							setTheme={boxTheme[setTheme]}
						/>
					</Grid>
				</ColumnWrapper>
				<BoxBg theme={boxTheme[setTheme]} />
			</Grid>
		</Wrapper>
	)
}

SustainabilityBlock.defaultProps = {
	mediaPlacement: 'left',
}

export default SustainabilityBlock
