import React from 'react'
import styled from '@emotion/styled'
import { useScrollPercentage } from 'react-scroll-percentage'

const parallaxOffset = (num, outMin, outMax) => {
	const offset = num * (outMax - outMin) / (1 - 0) + outMin
	// if (!offset) {
		// return 0
	// } else {
		return offset
	// }
}

const calculateTransform = (scroll, posYStart, posYEnd, rotateStart, rotateEnd, scaleStart, scaleEnd, scrollUnit) => {
	let transformValue = ''

	if (posYStart === 0 && posYEnd === 0 && scaleStart === 1 && scaleEnd === 1 && rotateStart === 0 && rotateEnd === 0) {
		transformValue = 'none'
	} else {
		if (posYStart !== 0 || posYEnd !== 0) {
			transformValue += ' translate3d(0, ' + parallaxOffset(scroll, posYStart, posYEnd) + scrollUnit + ', 0)'
		}

		if (scaleStart !== 1 || scaleEnd !== 1) {
			transformValue += ' scale(' + (parallaxOffset(scroll, scaleStart, scaleEnd) || 1) + ')'
		}

		if (rotateStart !== 0 || rotateEnd !== 0) {
			transformValue += ' rotate(' + (parallaxOffset(scroll, rotateStart, rotateEnd) || 0) + 'deg)'
		}
	}

	return transformValue
}

const ParallaxStyle = styled.div`
	display: inline-block;
	vertical-align: top;
	${ ({ disabled, scroll, posYStart, posYEnd, rotateStart, rotateEnd, scaleStart, scaleEnd, scrollUnit }) => !disabled ? `
		// transition: transform .1s ease-in-out;
		transition: transform 5s cubic-bezier(.06,.83,.1,1);
		transform: ${ calculateTransform(scroll, posYStart, posYEnd, rotateStart, rotateEnd, scaleStart, scaleEnd, scrollUnit) };
	` : '' };
`

const Parallax = ({ children, className, posYStart, posYEnd, scrollUnit, rotateStart, rotateEnd, scaleStart, scaleEnd }) => {
	const [ref, percentage] = useScrollPercentage({ threshold: 0 })
	if (children.length > 1) {
		return (
			<div ref={ref} className={className}>
				{children.map((item, index) => {
					const {
						posYStart,
						posYEnd,
						rotateStart,
						rotateEnd,
						scaleStart,
						scaleEnd,
						children,
						css
					} = item.props
					return (
						<ParallaxStyle
							css={css}
							scroll={percentage.toPrecision(3)}
							className={className}
							posYStart={posYStart}
							posYEnd={posYEnd}
							rotateStart={rotateStart}
							rotateEnd={rotateEnd}
							scaleStart={scaleStart}
							scaleEnd={scaleEnd}
							scrollUnit={item?.props?.scrollUnit || scrollUnit}
						>
							{children}
						</ParallaxStyle>
					)
				})}
			</div>
		)
	}

	return (
		<ParallaxStyle
			ref={ref}
			scroll={percentage.toPrecision(3)}
			className={className}
			posYStart={posYStart}
			posYEnd={posYEnd}
			rotateStart={rotateStart}
			rotateEnd={rotateEnd}
			scaleStart={scaleStart}
			scaleEnd={scaleEnd}
			scrollUnit={scrollUnit}
		>
			{children}
		</ParallaxStyle>
	)
}

Parallax.defaultProps = {
	posYStart: 0,
	posYEnd: 0,
	rotateStart: 0,
	rotateEnd: 0,
	scaleStart: 1,
	scaleEnd: 1,
	scrollUnit: 'px'
}

export default Parallax
