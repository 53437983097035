import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import Grid from 'src/components/Grid'
import Section from 'src/components/Section'
import { typography, colors, util, mq } from 'src/styles'
import { use100vh } from 'react-div-100vh'

const Wrapper = styled(Section)`
	--panel-width: 350px;
	${ mq.extraLargeAndUp } {
		--panel-width: 400px;
	}
	${ ({ isFirstSection }) => isFirstSection ? `
		padding-top: 0 !important;
	` : '' }
	> div > div {
		clip-path: url(#raggedThumb2);
		background: white;
	}
	#stockist-widget.stockist-responsive  {
		position: relative;
		> .stockist-search-form {
			padding: 30px 30px 20px;
			margin: 0;
			${ mq.minWidth(769) } {
				padding: 40px 30px 20px 40px;
				margin-bottom: -45px;
				width: var(--panel-width) !important;
			}
		}
		> .stockist-map {
			margin: 0 !important;
			left: var(--panel-width) !important;
		}
	}
	#stockist-widget .stockist-side-panel {
		${ typography.bodyTiny }
		padding: 30px 20px 0;
		${ mq.minWidth(769) } {
			display: flex;
			flex-direction: column;
			padding: 40px 30px 0 40px;
			width: var(--panel-width) !important;
			> div {
				width: 100%;
			}
			.stockist-search-form {
				flex-grow: 0;
				flex-shrink: 0;
			}
			.stockist-result-list {
				flex-grow: 1;
				padding-bottom: 20px;
				// flex-shrink: 0;
			}
		}
		line-height: 1.5em;
		a {
			&:hover {
				color: ${ colors.mainColor };
			}
		}
		.stockist-result-list ul {
			margin-right: 0;
			li {
				padding-top: 12px;
				padding-bottom: 12px;
			}
		}
		.stockist-list-result {
			border: none;
			&.stockist-selected {
				border: none;
				background: ${ colors.blush };
				clip-path: url(#raggedThumb1);
				.stockist-icon {
					color: ${ colors.mainColor };
				}
			}
			& > div {
				padding-left: 38px;
			}
			.stockist-result-addr-country {
				display: none;
			}
			.stockist-result-distance {
				width: 16px;
				padding-left: 16px;
				.stockist-icon {
					margin-top: -1px;
					font-size: 14px;
				}
			}
		}
	}
	#stockist-widget .stockist-query-entry {
		padding: 0 10px;
		${ mq.minWidth(769) } {
			padding: 0 0 0 10px;
		}
		${ mq.largerAndUp } {
			padding: 0 0 0 20px;
		}
		.stockist-search-field {
			border: none;
			border-radius: 6px;
			background: ${ colors.blush };
			${ typography.bodyTiny }
			line-height: 1em;
			padding-top: .3em;
		}
	}
	#stockist-widget .stockist-search-button button {
		border-radius: 50%;
		width: 34px !important;
		padding: 0 !important;
		background: ${ colors.mainColor };
		&:hover {
			background: ${ colors.pink5 };
		}
	}
	#stockist-widget .stockist-horizontal {
		${ mq.minWidth(769) } {
			${ ({ fullHeight }) => util.responsiveStyles('height', fullHeight - 220, fullHeight - 220, fullHeight - 200, fullHeight - 150, true) };
			.stockist-horizontal,
			.stockist-side-panel,
			.stockist-map {
				${ ({ fullHeight }) => util.responsiveStyles('height', fullHeight - 220, fullHeight - 220, fullHeight - 200, fullHeight - 150, true) };
			}
			.stockist-result-list {
				height: 100%;
			}
			.stockist-map {
				left: var(--panel-width);
			}
		}
	}
	#stockist-widget .stockist-powered-by-link {
		border-top: 1px solid ${ colors.hrColor };
		padding-bottom: 20px !important;
		padding-top: 10px;
		margin-top: 0;
		${ mq.minWidth(769) } {
			margin-left: 20px;
			width: 100%;
		}
		color: ${ colors.lightTextColor };
		a {
			color: ${ colors.lightTextColor };
			&:hover {
				color: ${ colors.textColor };
			}
		}
	}
`

const StockistMap = ({
	className,
  nextTheme,
  prevTheme,
  theme = 'default',
  isFirstSection,
	isLastSection,
  index
}) => {
	const { allContentfulSiteSettings } = useStaticQuery(
		graphql`
			query {
				allContentfulSiteSettings(filter: {internalName: {regex: "/^((?!PLACEHOLDER).)*$/"}}) {
					nodes {
				    ageGateImage {
				      fixed(width: 900, quality: 80) {
				        src
				      }
				    }
					}
				}
			}
		`
	)

	const fullHeight = use100vh()

	useEffect(() => {
		const script = document.createElement('script')
		script.src = '//stockist.co/embed/v1/widget.min.js'
		script.async = true
		document.body.appendChild(script)
		return () => {
			document.body.removeChild(script)
		}
	}, [])

	return (
		<>
			<Wrapper
				className={className}
				prevTheme={prevTheme}
				setTheme={theme}
				nextTheme={nextTheme}
				isFirstSection={isFirstSection}
				isLastSection={isLastSection}
				index={index}
				fullHeight={fullHeight}
			>
				<Grid small='1 [12] 1' extraLarge='3 [22] 3'>
					<div data-stockist-widget-tag="u14442">Loading...</div>
				</Grid>
			</Wrapper>
			<script dangerouslySetInnerHTML={{
				__html: `
					(function(s,t,o,c,k){c=s.createElement(t);c.src=o;c.async=1;
						k=s.getElementsByTagName(t)[0];k.parentNode.insertBefore(c,k);
						});
				`
			}}/>
		</>
	)
}

export default StockistMap
