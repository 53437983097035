import React from 'react'
import styled from '@emotion/styled'
import Slider from 'react-slick'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import ClippedImage from 'src/components/ClippedImage'
import Grid from 'src/components/Grid'
import Link from 'src/components/Link'
import Section from 'src/components/Section'
import TextLockup from 'src/components/TextLockup'

import { colors, mq, animations, globals } from 'src/styles'

const Wrapper = styled(Section)``

const Info = styled(TextLockup)`
	
`

const InstaFeedWrap = styled.div`
	${ globals.verticalSpacing('margin-top', 0.5) }
	display: flex;
	position: relative;
	padding-left: 6px;
	padding-right: 6px;
	${ mq.largeAndBelow } {
		display: block;
		padding: 0;
	}
`

const StyledGatsbyImage = styled(ClippedImage)`
	.gatsby-image-wrapper > div {
		padding-top: 100% !important;
	}
`

const ImageWrap = styled(Link)`
	display: block;
	flex-grow: 1;
	padding: 0 6px;
`

const StyledSlider = styled(Slider)`
	padding-bottom: 40px;
	.slick-slide {
		width: 28vw;
		${ mq.mediumAndBelow } {
			width: 50vw;
		}
	}
`

const SlideshowDots = styled.ul`
	list-style: none;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
`

const SlideshowDot = styled.li`
	width: 10px;
	height: 10px;
	color: ${ colors.mainColor };
	background: currentcolor;
	cursor: pointer;
	border-radius: 50%;
	margin: 0 5px;
	border: 2px solid currentcolor;
	transform: scale(0.6);
	transition: transform ${ animations.mediumSpeed } ease-in-out,
	background ${ animations.mediumSpeed } ease-in-out;
	.slick-active & {
		transform: none;
		background: transparent;
	}
`

const InstagramFeed = ({
	className,
	theme,
	headline,
	text,
	button,
	prevTheme,
	nextTheme,
	isLastSection,
	isFirstSection,
	index,
	instagramUrl,
	images
}) => {
	const settings = {
		dots: true,
		arrows: false,
		infinite: true,
		autoplay: false,
		centerMode: true,
		swipe: true,
		accesibility: true,
		speed: 500, // swipe animation speed
		variableWidth: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		appendDots: dots => <SlideshowDots>{dots}</SlideshowDots>,
		customPaging: (slider, i) => (
			<SlideshowDot active={slider === i}>
				{i}
				{slider.current}
			</SlideshowDot>
		),
	}

	return (

		<Wrapper
			className={className}
			prevTheme={prevTheme}
			setTheme={theme}
			nextTheme={nextTheme}
			isFirstSection={isFirstSection}
			index={index}
		>
			<Grid small="1 [12] 1">
				<div>
					<Info
						eyebrow={headline}
						text={text}
						textSize='bodySmall'
						alignment='center'
					/>
				</div>
			</Grid>
			<ResponsiveComponent
				small={
					<InstaFeedWrap>
						<StyledSlider {...settings}>
							{images.map((image, index) => {
								return (
									<ImageWrap to={instagramUrl} external target='_blank'>
										<StyledGatsbyImage
											// image={image.image.gatsbyImageData}
											image={image.image}
											aspectRatio='1'
											// alt={image?.image?.file?.fileName}
											clipPathId="raggedThumb1"
										/>
									</ImageWrap>
								)
							})}
						</StyledSlider>
					</InstaFeedWrap>
				}
				large={
					<InstaFeedWrap>
						{images.map((image, index) => {
							return (
								<ImageWrap to={instagramUrl} external target='_blank'>
									<StyledGatsbyImage
										// image={image.image.gatsbyImageData}
										image={image.image}
										aspectRatio='1'
										// alt={image?.image?.file?.fileName}
										clipPathId="raggedThumb1"
									/>
								</ImageWrap>
							)
						})}
					</InstaFeedWrap>
				}
			/>

		</Wrapper>
	)
}

export default InstagramFeed
