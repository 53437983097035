import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import TextLockup from 'src/components/TextLockup'
import Grid from 'src/components/Grid'
import ScrollEntrance from 'src/components/ScrollEntrance'
import { GatsbyImage } from 'gatsby-plugin-image'
import { globals, colors, util } from 'src/styles'

const Wrapper = styled(Section)`
  ${ ({ alignment }) =>
    alignment !== 'right' ? `
    text-align: ${ alignment };
  ` : '' }
  h1, h2, h3, h4, h5, h6, p {
    ${ ({ alignment }) =>
      alignment === 'center' ? `
      margin-left: auto;
      margin-right: auto;
    ` : '' }
  }
  h1,
  h2,
  h3 {
    max-width: 16em;
  }
  h4,
  h4 {
    max-width: 26em;
  }
  h6 {
    max-width: 32em;
  }
  p {
    max-width: 35em;
    ${ ({ alignment }) =>
      alignment === 'center' ? `
      max-width: 45em;
    ` : `
      max-width: 35em;
    ` }
  }
`

const SectionContent = styled.div`
  ${ ({ type, boxBg, boxColor }) => type === 'box' ? `
    position: relative;
    ${ globals.verticalSpacing('padding-top', 0.85) }
    ${ globals.verticalSpacing('padding-bottom', 0.85) }
    ${ globals.verticalSpacing('padding-left') }
    ${ globals.verticalSpacing('padding-right') }
    color: ${ colors[boxColor] || colors.bgColor };
    > svg {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      * {
        fill: ${ colors[boxBg] || colors.bgColor };
      }
    }
  ` : '' }
`

const SectionIcon = styled(GatsbyImage)`
  ${ ({ iconSize }) => `
    ${ iconSize === 'large' ? util.responsiveStyles('width', 600, 500, 400, 300) : '' }
    ${ iconSize === 'medium' ? util.responsiveStyles('width', 450, 350, 300, 200) : '' }
    ${ iconSize === 'small' || !iconSize ? util.responsiveStyles('width', 100, 80, 75, 70) : '' }
  ` }
  z-index: 2;
  display: inline-block;
  margin-bottom: 30px;
`

const TextContent = styled(TextLockup)`
  ${ ({ type }) =>
    type === 'box' ? `
      position: relative;
      z-index: 2;
      padding-bottom: 16px;
    ` : ''
  }
  ${ ({ type }) =>
    type === 'legal' ? `
      .eyebrow {
        font-size: 40px;
      }
      p {
        max-width: 100%;
      }
    ` : ''
  }
`

const TextSection = ({
  className,
  nextTheme,
  prevTheme,
  theme,
  eyebrow,
  handwrittenEyebrow,
  underlineColor,
  text,
  icon,
  iconSize,
  paragraphSize,
  actions,
  alignment,
  isFirstSection,
  index,
  type,
  id,
  padBottom,
  headlineStyle = 'default'
}) => {
  if (!text && !eyebrow && !actions) {
    return false
  }
  const align = {
    // same as in WideMedia.jsx
    left: {
      small: 'container',
      medium: 'container',
      large: 'container',
      larger: 'container',
    },
    center: {
      small: 'container',
      medium: 'container',
      large: 'container',
      larger: 'container',
    },
    right: {
      small: 'container',
      medium: '7 [6] 1',
      large: '7 [6] 1',
      larger: '7 [5] 2',
    },
    box: {
      small: '1 [24] 1',
      medium: '2 [10] 2',
      large: '2 [10] 2',
      larger: '3 [8] 3',
    },
    legal: {
      small: 'container',
      medium: '2 [10] 2',
      large: '3 [8] 3',
      larger: '4 [6] 4',
    },
  }

  if (!alignment || alignment === null) {
    alignment = 'left'
  }

  if (!theme || theme === null) {
    theme = 'default'
  }

  const boxTheme = {
    default: {
      background: 'pink2',
      color: 'textColor'
    },
    pink: {
      background: 'pink2',
      color: 'textColor'
    },
    midPink: {
      background: 'pink',
      color: 'textColor'
    },
    darkPink: {
      color: 'white',
      background: 'pink5'
    },
    honey: {
      color: 'white',
      background: 'honey'
    },
    orange: {
      color: 'white',
      background: 'orange'
    },
    lavender: {
      color: 'white',
      background: 'lavender'
    }
  }

  return (
    <Wrapper
      className={className}
      prevTheme={prevTheme}
      // setTheme={type === "box" ? (prevTheme || 'default') : theme}
      setTheme={theme}
      nextTheme={nextTheme}
      alignment={alignment}
      isFirstSection={isFirstSection}
      index={index}
      padBottom={padBottom}
    >
      <Grid
        small={type === 'box' || type === 'legal' ? align[type].small : align[alignment].small}
        medium={type === 'box' || type === 'legal' ? align[type].medium : align[alignment].medium}
        large={type === 'box' || type === 'legal' ? align[type].large : align[alignment].large}
        extraLarge={type === 'box' || type === 'legal' ? align[type].larger : align[alignment].larger}
      >
        <SectionContent type={type} setTheme={theme} boxBg={boxTheme[theme].background} boxColor={boxTheme[theme].color}>
          {icon && (
            <ScrollEntrance>
              <SectionIcon image={icon.gatsbyImageData} iconSize={iconSize || 'small'} />
            </ScrollEntrance>
          )}
          <TextContent
            type={type}
            eyebrow={eyebrow}
            handwrittenEyebrow={handwrittenEyebrow}
            text={text}
            textSize={paragraphSize}
            actions={actions}
            theme={theme}
            setTheme={theme}
            entranceDelay={icon ? 1 : 0}
            underlineColor={underlineColor}
            headlineStyle={headlineStyle}
          />
          {type === 'box' && (
            <svg viewBox="0 0 995 447" preserveAspectRatio="none">
              <g
                id="Symbols"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <path
                  d="M18.590768,439.385946 C-4.86722194,418.402341 11.0326441,377.968932 8.50484345,349.012715 C5.97704281,306.01712 21.1286798,268.286292 11.0174773,225.290697 C-1.5810811,164.949451 -6.56084837,100.340233 13.5452779,40.1464002 C23.6564805,8.55779985 43.8687744,4.17049424 132.341797,4.17049424 C294.121038,4.17049424 450.839622,-1.97699838 612.618863,0.655384981 C713.730888,2.41030723 817.370715,0.655384981 918.48274,2.41030723 C1006.95576,4.16522947 984.200501,36.6365558 986.728302,60.3280061 C991.783903,148.074118 999.377416,236.697692 991.794014,323.566343 C989.266214,351.645099 994.321815,378.846393 991.794014,406.925149 C991.794014,414.822299 996.849616,430.6166 984.210613,436.758827 C969.043809,445.533439 931.126799,444.655978 905.848793,445.533439 C741.541751,449.920744 579.76251,442.895791 415.455468,444.650713 C326.982446,445.528174 238.509424,445.528174 150.036401,445.528174 C122.230594,445.528174 33.7575718,449.91548 18.590768,439.385946"
                  id="Fill-1-Copy"
                  fill="#EDD8D0"
                ></path>
              </g>
            </svg>
          )}
        </SectionContent>
      </Grid>
    </Wrapper>
  )
}

export default TextSection
