import { useStaticQuery, graphql } from 'gatsby'
import { decodeProductId } from 'src/utils/format'
import ClippedImage from 'src/components/ClippedImage'
import Link from 'src/components/Link'
import styled from '@emotion/styled'
import { colors, mq, typography, globals, util } from 'src/styles'

const SwitcherWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  margin-top: 30px;
  > div {
    width: 70px;
  }
  .img-wrapper {
    clip-path: url(#raggedThumb1);
    position: relative;
    aspect-ratio: 1;
    width: 100%;
    margin-bottom: 6px;
    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${ typography.bodyTiny }
    transition: opacity .3 ease-in-out;
    opacity: .6;
    &:not(.active) {
      &:hover {
        opacity: 1;
      }
    }
    &.active {
      opacity: 1;
    }
  }
`

const BeverageProductSwitcher = ({ beverages, pathname }) => {
  if (!beverages || beverages?.length < 4) {
    return false
  }

  const { allShopifyProduct } = useStaticQuery(
		graphql`
			query {
				allShopifyProduct {
			    edges {
			      node {
              title
			        handle
			        shopifyId
              featuredImage {
                src
                width
                height
              }
              variants {
                ...Variant
              }
			      }
			    }
			  }
			}
		`
	)

  const getProduct = id => {
		const decodedId = decodeProductId(id)
		const product = allShopifyProduct?.edges?.filter(product => product.node.shopifyId === decodedId)[0]?.node
    console.log(product)
		return {
      link: '/shop/' + product?.handle,
      title: product?.title,
      image: product?.featuredImage?.src
    }
	}

  const bevItems = beverages.filter(item => item?.node?.shopifyProductId !== 'placeholder')

  return (
    <SwitcherWrapper>
      {bevItems?.map(bev => {
        const item = bev?.node
        let itemUrl = false
        let itemTitle
        let itemImage

        if (item?.shopifyProductId) {
          itemUrl = getProduct(item.shopifyProductId)?.link
          itemTitle = getProduct(item.shopifyProductId)?.title
          itemImage = getProduct(item.shopifyProductId)?.image
        }
        if (!itemUrl) {
          return false
        }

        const active = pathname === itemUrl

        console.log(pathname, itemUrl)

        return (
          <div key={itemUrl}>
            <Link to={itemUrl} className={active ? 'active' : ''}>
              <div className='img-wrapper'>
                <img src={itemImage} alt={itemTitle} />
              </div>
              {itemTitle}
            </Link>
          </div>
        )
      })}
    </SwitcherWrapper>
  )
}

export default BeverageProductSwitcher
