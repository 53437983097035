import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Section from 'src/components/Section'
import { GatsbyImage } from 'gatsby-plugin-image'
import Grid from 'src/components/Grid'
import { colors, util, typography, animations, globals } from 'src/styles'
import TextLockup from 'src/components/TextLockup'
import Parallax from 'src/components/Parallax'
import ScrollEntrance from 'src/components/ScrollEntrance'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import { headerHeight } from 'src/components/Header'

import NumberCircleSvg from 'src/assets/images/cart-circle.svg'

const Wrapper = styled(Section)`
  color: ${ colors.textColor };
  background: transparent;
  overflow: visible;
  ${ globals.verticalSpacing('margin-top', -0.3) }
  ${ globals.verticalSpacing('margin-bottom', -0.5) }
  p {
    margin-left: auto;
    margin-right: auto;
    color: ${ colors.textColor };
    max-width: 26em;
  }
`

const ParallaxSectionWrap = styled.div`
  ${ ({ height }) =>
    height !== 'auto' ? `
    position: relative;
    z-index: 2;
  ` : '' }
`

const ContentWrap = styled.div`
  position: relative;
`

const ParallaxItem = styled(Parallax)`
  position: relative;
  z-index: 1;
  width: 100%;
`

const OverlayContent = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  text-align: center;
  h1,
  h2,
  h3 {
    max-width: 10em;
  }
  h4,
  h4 {
    max-width: 12em;
  }
  h6 {
    max-width: 16em;
  }
  p {
    max-width: 36em;
  }
`

const OverlaySection = styled.div`
  ${ ({ isFirstSection }) =>
    isFirstSection &&
    `
    ${ headerHeight('padding-top') }
  ` }
`

const NumberCircle = styled.div`
  ${ util.responsiveStyles('width', 120, 100, 90, 80) }
  ${ util.responsiveStyles('margin-bottom', 32, 32, 28, 24) }
  position: relative;
  margin: 0 auto 50px;
  p {
    margin: 0;
    position: absolute;
    z-index: 1;
    ${ typography.h3 }
    ${ util.responsiveStyles('font-size', 40, 30, 24, 22) }
    top: 0;
    left: 0;
    width: 100%;
    height: 90%;
    line-height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0;
    color: ${ colors.pink4 };
  }
  svg {
    width: 100%;
    height: 100%;
    transform-origin: 49% 51%;
    animation: ${ animations.rotate } 10s linear infinite;
    * {
      fill: ${ colors.white };
    }
  }
`

const SectionText = styled(TextLockup)`
  .headline, h5 {
    ${ typography.h6 }
    ${ util.responsiveStyles('margin-bottom', 32, 32, 28, 24) }
  }
`

const ParallaxSection = ({
  largeMedia,
  mediumMedia,
  smallMedia,
  width,
  theme,
  prevTheme,
  nextTheme,
  number,
  text,
  actions,
  isFirstSection,
  index,
}) => {
  if (!largeMedia) {
    return false
  }

  const fullWidth = width === 'fullWidth'

  const hasOverlay = true

  const renderMedia = mediaItems => {
    if (mediaItems && mediaItems.length > 0) {
      return mediaItems.map((mediaItem, index) => {
        return (
          <div
            key={mediaItem.id}
            css={css`
              z-index: 1;
              width: 100%;
              ${ index === 0 ? `
                position: relative;
              ` : `
                position: absolute !important;
                top: 0;
                left: 0;
                width: 100%;
              ` }
            `}
            // posYStart={4 * (index + 1) * (index % 2 ? 1 : -1)}
            // posYEnd={4 * (index + 1)  * (index % 2 ? -1 : 1)}
            posYStart={5.5 * (index + 1)}
            posYEnd={-5.5 * (index + 1)}
            firstItem={index === 0}
            scrollUnit="%"
          >
            <GatsbyImage
              image={mediaItem.gatsbyImageData}
              loading={isFirstSection ? 'eager' : 'lazy'}
              alt={mediaItem.file.fileName}
              quality={100}
            />
          </div>
        )
      })
    }
    return false
  }

  return (
    <Wrapper
      nextTheme={nextTheme || 'default'}
      prevTheme={prevTheme || 'default'}
      setTheme={theme || 'default'}
      padded={!fullWidth}
      index={index}
    >
      <ParallaxSectionWrap>
        <Grid small="[1]">
          <ContentWrap>
            <ResponsiveComponent
              small={<ParallaxItem scrollUnit='%'>{renderMedia(smallMedia)}</ParallaxItem>}
              medium={<ParallaxItem scrollUnit='%'>{renderMedia(mediumMedia)}</ParallaxItem>}
              large={<ParallaxItem scrollUnit='%'>{renderMedia(largeMedia)}</ParallaxItem>}
            />
            {/* <div style={{ position: 'relative' }}>
              {renderMedia(largeMedia, 'large')}
            </div> */}
            {hasOverlay && (
              <OverlayContent>
                <OverlaySection
                  isFirstSection={isFirstSection}
                  nextTheme={nextTheme}
                  prevTheme={prevTheme}
                  setTheme={theme}
                >
                  <Grid small="2 [10] 2" medium="3 [8] 3" large="9 [8] 9">
                    <div>
                      {number && (
                        <ScrollEntrance>
                          <NumberCircle>
                            <p>{number}</p>
                            <NumberCircleSvg />
                          </NumberCircle>
                        </ScrollEntrance>
                      )}
                      <SectionText
                        text={text}
                        textSize="body"
                        headlineSize='h6'
                        actions={actions}
                        alignment="center"
                        entranceDelay={number ? 1 : 0}
                      />
                    </div>
                  </Grid>
                </OverlaySection>
              </OverlayContent>
            )}
          </ContentWrap>
        </Grid>
      </ParallaxSectionWrap>
    </Wrapper>
  )
}

ParallaxSection.defaultProps = {
  width: 'margins',
  height: 'auto',
}

export default ParallaxSection
